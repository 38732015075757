<template>
  <div>
    <div>
      <b-tabs content-class="mt-3" justified id="transactionTabs">
        <b-tab title="Send Email" id="transactionTab" active>
          <send-email></send-email>
        </b-tab>
        <!-- <b-tab title="Send SMS" id="transactionTab">
          <send-sms></send-sms>
        </b-tab> -->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import SendEmail from "./SendEmail.vue";
// import SendSms from "./SendSms.vue";

export default {
  components: {
    sendEmail: SendEmail,
    // sendSms: SendSms,
  },
};
</script>

<style >
#transactionTabs ul li #transactionTab___BV_tab_button__ {
  background-color: #288745;
}

#transactionTabs ul li #transactionTab___BV_tab_button__ {
  color: white;
}
#transactionTabs ul li .active {
  color: white !important;
  font-weight: 600;
}
</style>

<template>
  <div>
    <div class="sendEmail">
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <div class="closeBtn mb-2 text-right">
              <p @click="$router.push({ name: 'institute' })" class="btn m-0">
                <i class="fas fa-window-close fa-4x text-danger"></i>
              </p>
            </div>
            <div>
              <h6 class="text-center alert alert-success" v-if="responseSuccess.created">
                {{ responseSuccess.created[0] }}
              </h6>
              <b-form @submit.prevent="postEmailTransaction()">
                <b-form-group>
                  <b-form-checkbox size="sm" v-model="notRegistered">Non registered users?</b-form-checkbox>
                </b-form-group>
                <b-form-group v-if="notRegistered">
                  <!-- <b-form-input
                    class="w-50"
                    placeholder="Enter email..."
                    :disabled="sendEmailBtn.disabled"
                    v-model="enterEmail"
                    @keyup.space="addEmail()"
                    @focusout="addEmail()"
                  ></b-form-input> -->

                  <b-form-tags input-id="tags-remove-on-delete"
                    :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }" v-model="emails" separator="'
             ,;'" placeholder="Enter email separated by commas" remove-on-delete no-add-on-enter tag-pills
                    tag-variant="primary" v-b-popover.hover.bottom="
                      'john8@gmail.com,918765******,test@gmail.com, test2@gmail.com'
                    "></b-form-tags>


                  <!-- <div class="emails d-flex align-items-center flex-wrap m-3" v-if="emails.length != 0">
                    <h5 class="text-dark">Emails:</h5>
                    <span class="ml-1 p-0 bg-success text-white border-0 rounded" v-for="(email, emailIndex) in emails"
                      :key="emailIndex" style="font-size: 12px">
                      {{ email }}
                    </span>
                  </div> -->
                </b-form-group>
                <b-form-group>
                  <b-form-input class="w-50" placeholder="Subject" v-model="emailSubject"
                    :disabled="sendEmailBtn.disabled">
                  </b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-textarea class="w-50" placeholder="Enter Message..." rows="5" max-rows="6"
                    v-model="messageText" :disabled="sendEmailBtn.disabled"></b-form-textarea>
                </b-form-group>
                <div class="loading" v-if="fetchData">
                  <load-data></load-data>
                </div>
                <div v-else>
                  <b-form-group v-if="!notRegistered">
                    <div class="d-flex align-items-baseline justify-content-center">
                      <h6 class="text-dark font-weight-bolder">
                        Select Users:
                      </h6>
                      <b-button class="ml-2" variant="danger" size="sm" v-if="selectedUsers.length != 0"
                        @click="clearAll()">Clear All</b-button>
                    </div>
                    <br />

                    <div>
                      <div class="row">
                        <div class="col" style="height: 200px; overflow-y: scroll">
                          <div>
                            <p class="font-weight-bolder text-dark">
                              Institution Users
                            </p>
                            <b-form-checkbox size="sm" v-model="allUsers.selected" :disabled="allUsers.disabled"
                              @change="selectAllUsers()">All Institution Users</b-form-checkbox>
                          </div>
                          <br />
                          <div>
                            <p class="font-weight-bolder text-dark">
                              {{
                                  getCustomNames.userGroup.custom_name != null
                                    ? getCustomNames.userGroup.custom_name
                                    : "Usergroup"
                              }}({{
    getCustomNames.userGroup.custom_name != null
      ? getCustomNames.userGroup.custom_name
      : "Usergroup"
}}
                              Name -
                              {{
                                  getCustomNames.userGroup.custom_name != null
                                    ? getCustomNames.userGroup.custom_name
                                    : "Usergroup"
                              }}
                              Code)
                            </p>
                            <b-form-checkbox-group size="sm" v-model="selectedGroup" :options="groupOptions" :class="{
                              'is-invalid': responseErrors.noGroupUsersFound,
                            }" stacked @change="getEnrolledGroupUsers()"></b-form-checkbox-group>
                            <span class="text-danger" v-if="responseErrors.noGroupUsersFound">{{
                                responseErrors.noGroupUsersFound[0]
                            }}</span>
                          </div>
                          <br />
                          <div>
                            <p class="font-weight-bolder text-dark">
                              {{
                                  getCustomNames.userSubgroup.custom_name != null
                                    ? getCustomNames.userSubgroup.custom_name
                                    : "Usersubgroups"
                              }}({{
    getCustomNames.userSubgroup.custom_name != null
      ? getCustomNames.userSubgroup.custom_name
      : "Usersubgroup"
}}
                              Name -
                              {{
                                  getCustomNames.userGroup.custom_name != null
                                    ? getCustomNames.userGroup.custom_name
                                    : "Usergroup"
                              }}
                              Name -
                              {{
                                  getCustomNames.userSubgroup.custom_name != null
                                    ? getCustomNames.userSubgroup.custom_name
                                    : "Usersubgroup"
                              }}
                              Code)
                            </p>
                            <b-form-checkbox-group size="sm" v-model="selectedSubgroup" :options="subgroupOptions"
                              :class="{
                                'is-invalid':
                                  responseErrors.noSubgroupUsersFound,
                              }" stacked @change="getEnrolledSubgroupUsers()"></b-form-checkbox-group>
                            <span class="text-danger" v-if="responseErrors.noSubgroupUsersFound">{{
                                responseErrors.noSubgroupUsersFound[0]
                            }}</span>
                          </div>
                        </div>
                        <div class="col" style="height: 200px; overflow-y: scroll" v-if="selectedUsers.length != 0">
                          <div class="selectedUsers d-flex flex-wrap">
                            <p class="bg-success p-1 ml-1 text-white border-0 rounded" style="font-size: 12px"
                              v-for="(user, userIndex) in selectedUsers" :key="userIndex">
                              {{ user.name }}
                              <span class="text-white btn p-0 m-0 ml-1" @click="selectedUsers.splice(userIndex, 1)"><i
                                  class="fas fa-times-circle"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                </div>

                <b-form-group>
                  <b-button type="submit" variant="primary" size="md" :disabled="sendEmailBtn.disabled">{{
                      sendEmailBtn.text
                  }}</b-button>
                  <b-button variant="danger" size="md" class="ml-3" @click="clear()" :disabled="sendEmailBtn.disabled">
                    Clear</b-button>
                </b-form-group>
              </b-form>
            </div>
          </b-col>
          <!-- <b-col md="6">
            <div>
              <b-list-group id="sentMessages">
                <b-list-group-item
                  v-for="(report, reportIndex) in smsTransactions"
                  :key="reportIndex"
                >
                  <div class="d-flex align-items-center">
                    <p>{{ report.dateTime }} <br /></p>
                    <p class="ml-5">
                      {{ report.message }}
                    </p>
                    <p class="ml-5">Count: {{ report.smsCount }}</p>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <br />
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="sentMessages"
                align="center"
                size="lg"
              ></b-pagination>
            </div>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    this.fetchData = await true;
    await this.getInstitutionUsers(); // with groups and subgroups
    await this.getGroupList();
    this.fetchData = await false;
    // await this.smsTransactionReport(); // with groups and subgroups
    this.$forceUpdate();
  },
  data() {
    return {
      // userOptions: ["Institute", "Groups", "Subgroups"],
      fetchData: false,
      selectedUsers: [],
      institutionUserOptions: [],
      multiselectValues: [],
      smsTransactions: [],
      messageText: "",
      emailSubject: "",
      perPage: 3,
      currentPage: 1,
      notRegistered: false,
      enterEmail: "",
      emails: [],
      sendEmailBtn: {
        text: "Send Email",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
      allUsers: {
        selected: false,
        disabled: false,
      },
      institutionUsers: [],
      groupUsers: {},
      subgroupUsers: {},
      groupOptions: [],
      subgroupOptions: [],
      selectedGroup: [],
      selectedSubgroup: [],
      selectedUsersObject: {},
      selected_users: [],
    };
  },

  methods: {
    addEmail() {
      this.enterEmail.replace(",", "");
      if (this.enterEmail == "") {
        return "Nothing";
      }
      if (this.enterEmail != "") {
        this.emails.push(this.enterEmail);
        this.enterEmail = "";
      } else {
        ("Do Nothing!");
      }
    },
    async selectAllUsers() {
      this.allUsers.disabled = true;
      if (this.selectedUsers.length != 0) {
        this.selectedUsers = await [];
      }
      this.selectedSubgroup = await [];
      this.selectedGroup = await [];
      await this.groupOptions.forEach((gUser) => {
        gUser.disabled = true;
      });
      this.selectedUsersObject = await {};
      this.selected_users = [];
      await this.subgroupOptions.forEach((sUser) => {
        sUser.disabled = true;
      });

      this.selectedUsers = await this.institutionUsers;
    },
    async clearAll() {
      this.selectedUsers = [];
      this.selected_users = [];
      this.selectedUsersObject = {};
      this.allUsers.selected = false;
      this.allUsers.disabled = false;
      this.groupUsers = {};
      this.subgroupUsers = {};
      this.selectedGroup = [];
      this.selectedSubgroup = [];
      await this.getGroupList();
    },
    getGroupList() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.groupSubgroups;
      axios
        .get(url)
        .then((response) => {
          let groupList = response.data;
          this.groupUsers = {};
          this.subgroupUsers = {};
          this.groupOptions = [];
          this.subgroupOptions = [];

          groupList.forEach((gUser) => {
            this.groupUsers[gUser.id] = {
              name: gUser.name,
              code: gUser.code,
              users: [],
              disabled: false,
            };
            this.groupOptions.push({
              value: gUser.id,
              text: `${gUser.name + "-" + gUser.code + "-Staff List"}`,
              disabled: false,
            });
            gUser.usersubgroups.forEach((sUser) => {
              this.subgroupUsers[sUser.id] = {
                name: sUser.name,
                code: sUser.code,
                users: [],
                disabled: false,
              };
              this.subgroupOptions.push({
                value: sUser.id,
                text: `${sUser.name +
                  "-" +
                  gUser.name +
                  "-" +
                  sUser.code +
                  "-Student List"}`,
                disabled: false,
              });
            });
          });
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getEnrolledGroupUsers() {
      // await this.selectedGroup.reverse();
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollUsersList;
      const url = userList.replace(
        "usergroup_id",
        this.selectedGroup[this.selectedGroup.length - 1]
      );
      await this.selectedGroup;
      await this.$axios
        .get(url)
        .then((response) => {
          let listOfUsersEnrolled = response.data;
          listOfUsersEnrolled.forEach((user) => {
            this.$set(
              this.selectedUsersObject,
              user.pivot.institution_user_id,
              {}
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "name",
              `${user.first_name + " " + user.last_name}`
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "id",
              user.pivot.institution_user_id
            );
            this.groupUsers[
              this.selectedGroup[this.selectedGroup.length - 1]
            ].users.push({
              id: user.pivot.institution_user_id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });

          if (
            this.groupUsers[this.selectedGroup[this.selectedGroup.length - 1]]
              .users.length != 0
          ) {
            this.selected_users = [];
            for (const user in this.selectedUsersObject) {
              this.selected_users.push({
                id: this.selectedUsersObject[user].id,
                name: this.selectedUsersObject[user].name,
              });
            }
            this.selectedUsers = this.selected_users;

            this.groupOptions.forEach((group) => {
              if (
                group.value == this.selectedGroup[this.selectedGroup.length - 1]
              ) {
                group.disabled = true;
              }
            });
          }
          if (
            this.groupUsers[this.selectedGroup[this.selectedGroup.length - 1]]
              .users.length == 0
          ) {
            this.$toast.warning("This usergroup has no users yet!", {
              position: "top",
              duration: 3000,
            });
            this.selectedGroup.splice(-1, 1);
          }
          this.fetchData = false;
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },

    async getEnrolledSubgroupUsers() {
      this.fetchData = true;
      let userList = this.$store.getters.getAPIKey.getEnrollSubgroupUsersList;
      const url = userList.replace(
        "usersubgroup_id",
        this.selectedSubgroup[this.selectedSubgroup.length - 1]
      );

      await this.$axios
        .get(url)
        .then((response) => {
          let listOfUsersEnrolled = response.data;
          listOfUsersEnrolled.forEach((user) => {
            this.$set(
              this.selectedUsersObject,
              user.pivot.institution_user_id,
              {}
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "name",
              `${user.first_name + " " + (user.last_name || "")}`
            );
            this.$set(
              this.selectedUsersObject[user.pivot.institution_user_id],
              "id",
              user.pivot.institution_user_id
            );

            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.push({
              id: user.pivot.institution_user_id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });
          if (
            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.length != 0
          ) {
            this.selected_users = [];
            for (const user in this.selectedUsersObject) {
              this.selected_users.push({
                id: this.selectedUsersObject[user].id,
                name: this.selectedUsersObject[user].name,
              });
            }
            this.selectedUsers = this.selected_users;

            this.subgroupOptions.forEach((group) => {
              if (
                group.value ==
                this.selectedSubgroup[this.selectedSubgroup.length - 1]
              ) {
                group.disabled = true;
              }
            });
          }
          if (
            this.subgroupUsers[
              this.selectedSubgroup[this.selectedSubgroup.length - 1]
            ].users.length == 0
          ) {
            this.$toast.warning("This usersubgroup has no users yet!", {
              position: "top",
              duration: 3000,
            });
            this.selectedSubgroup.splice(-1, 1);
          }

          this.fetchData = false;
          this.$forceUpdate();
          response;

          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async getInstitutionUsers() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        "/institutionusers/withgroupsandsubgroups";
      await axios
        .get(url)
        .then((response) => {
          var usersWithgroupSubgroup = response.data;

          usersWithgroupSubgroup.forEach((user) => {
            this.institutionUsers.push({
              id: user.id,
              name: `${user.first_name + " " + (user.last_name || "")}`,
            });
          });

          // usersWithgroupSubgroup.forEach((user) => {
          //   var institutionUserOption = {};

          //   institutionUserOption["id"] = user.id;
          //   institutionUserOption["email"] = user.email;

          //   institutionUserOption["name"] = user.first_name + " (";

          //   institutionUserOption["usersubgroups"] = [];
          //   user.usersubgroups.forEach((subgroup) => {
          //     // institutionUserOption['usersubgroups'].push(subgroup.name);
          //     institutionUserOption["name"] =
          //       institutionUserOption["name"] + subgroup.code + ",";
          //   });

          //   institutionUserOption["usergroups"] = [];
          //   user.usergroups.forEach((group) => {
          //     // institutionUserOption['usergroups'].push(group.name);
          //     institutionUserOption["name"] =
          //       institutionUserOption["name"] + group.code + ",";
          //   });

          //   institutionUserOption["name"] = institutionUserOption["name"] + ")";
          //   this.institutionUserOptions.push(institutionUserOption);
          // });
        })
        .catch((error) => {
          error;
        });
    },

    async smsTransactionReport() {
      const url = this.$store.getters.getAPIKey.mainAPI + "/smstransactions";
      await axios
        .get(url)
        .then((response) => {
          var reports = response.data;

          reports.forEach((report) => {
            var smsTransaction = {
              dateTime: report.created_at,
              message: report.data.message,
              smsCount: report.sms_count,
            };
            this.smsTransactions.push(smsTransaction);
          });
        })
        .catch((error) => {
          error;
        });
    },

    async postEmailTransaction() {
      this.sendEmailBtn.text = "Sending...";
      this.sendEmailBtn.disabled = true;
      let postData;
      await this.selectedUsers.forEach((user) => {
        this.multiselectValues.push(user.id);
      });
      if (this.notRegistered) {
        postData = await {
          emails: this.emails,
          message: { subject: this.emailSubject, content: this.messageText },
        };
      } else {
        postData = await {
          institution_users: this.multiselectValues,
          message: { subject: this.emailSubject, content: this.messageText },
        };
      }

      const url = this.$store.getters.getAPIKey.emailTransaction;

      await axios
        .post(url, postData)
        .then((response) => {
          this.responseSuccess["created"] = ["Emails sent successfully."];
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },
    clearResponse() {
      this.sendEmailBtn.text = "Send Email";
      setTimeout(() => {
        if (this.responseSuccess["created"]) {
          this.clear();
          this.responseSuccess = {};
        }
        this.sendEmailBtn.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
    clear() {
      this.emailSubject = "";
      this.messageText = "";
      this.selectedUsers = [];
      this.enterEmail = "";
      this.emails = [];
    },
  },
  computed: {
    getCustomNames() {
      return this.$store.getters.getCustomNamings;
    },
    // rows() {
    //   return this.messages.length;
    // },
    // clear() {
    //   this.messageText = "";
    //   this.selectedUsers = [];
    // },
  },
};
</script>

<style >

.sendEmail .col-md-6 .list-group .list-group-item {
  margin-top: 15px;
}
</style>
